import $ from 'jquery';

$(() => {
  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('tr').hide();
    event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    const time = new Date().getTime();
    const regexp = new RegExp($(this).data('id'), 'g');
    $(event.target)
      .parent()
      .children('table')
      .first()
      .children('tbody')
      .first()
      .children('tr:last')
      .after($(this).data('fields').replace(regexp, time));

    event.preventDefault();
  });

  $('form').on('click', '#display_layout_configuration_modal', function() {
    $('input#configuring_report_layout').val('true');
  });

  $('form').on('click', '#close_layout_configuration_modal', function() {
    $('input#configuring_report_layout').val('false');
  });

  $('form').on('click', '#display_bhcc_configuration_modal', function() {
    $('input#configuring_bhcc_settings').val('true');
  });

  $('form').on('click', '#close_bhcc_configuration_modal', function() {
    $('input#configuring_bhcc_settings').val('false');
  });

  $(document).on('click', '#licensee_search_by', function(event){
    var child = this.firstChild;
    var placeholder = child.text.slice(10);
    $(this).addClass('licensee-search-by').siblings().removeClass('licensee-search-by');
    document.getElementById('licensee_filter_data').placeholder = placeholder;
    document.getElementById('licensee_filter_search_by').value = placeholder;
    event.preventDefault();
  });
});
